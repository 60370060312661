/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import {apiGet, apiPost} from '../../../../../api';
import {Select} from "../../../../common/ui/select";
import {useForm} from "react-hook-form";
import {Button} from "../../../../common/ui/button";
import {CardBody, Col} from "reactstrap";
import {Input} from "../../../../common/ui/input";
// import Spinner from "../../../../common/spinner";
import {Modal} from "../../../../common/ui/modal";
import {BillBlock} from "../../../../common/billBlock";
import {getActiveClass} from "../../../../../helpers/className";
import {Icon} from "../../../../common/icon";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Spinner from "../../../../common/spinner";

const PrometeyTeach = ({setIsUpdatePage, isUpdatePage, telegramStatus, qualificationPartners}) => {
    const screenWidth = window.screen.width;
    const modalRef = useRef(null);

    const [prometeySubscribe, setPrometeySubscribe] = useState(true)
    const [qualifiedPartnerId, setQualifiedPartnerId] = useState(null)
    const [qualifiedPartnerName, setQualifiedPartnerName] = useState(null)
    const [investWallet, setInvestWallet] = useState(true)
    const [prometeyPrice, setPrometeyPrice] = useState(null)
    const [error, setError] = useState(null)
    const [educationKey, setEducationKey] = useState(null)
    const [isBuyEducation, setIsBuyEducation] = useState(null)
    const [waitingRequest, setWaitingRequest] = useState(false)
    const [educationBuyTime, setEducationBuyTime] = useState(null)

    const [videoUrl, setVideoUrl] = useState(null)
    const [isVideoVisible, setIsVideoVisible] = useState(false)

    // Модалки
    const [openBuySubscriptionModal, setOpenBuySubscriptionModal] = useState(false)
    const [openBuyModal, setOpenBuyModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [textAlert, setTextAlert] = useState('')


    // Кошельки для покупки
    const [investWalletUsdr, setInvestWalletUsdr] = useState(null)
    const [walletDataCrypto, setWalletDataCrypto] = useState(null)
    const [investWalletSmart, setInvestWalletSmart] = useState(null)

    const [alertView, setAlertView] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [textColor, setTextcolor] = useState('#000');


    const {register, handleSubmit, setValue, watch} = useForm();

    const iconColor = '#7c61bd';

    useEffect(() => {
        apiGet({
            url: `/prometey`
        }).then(res => {
            if (res.error)
                return;
            if (res.data === null) {
                return setPrometeySubscribe(null)
            }
            setIsUpdatePage(!isUpdatePage)
            setQualifiedPartnerId(res.data.qualifiedPartnerId)
            setQualifiedPartnerName(res.data?.qualifiedPartnerName)
            setEducationKey(res.data?.educationKey);
            setIsBuyEducation(res.data?.isBuyEducation);
            setEducationBuyTime(res.data?.educationBuyTime);
        });

    }, [successModal])

    useEffect(() => {

        // Запросить доступные крипто кошельки для покупки
        apiGet({
            url: '/wallet/crypto?onlyForPayProduct=1'
        }).then(res => {
            if (res.error)
                return;
            setWalletDataCrypto(res.data)
        })

        // Запросить USDR кошелек для покупки
        apiGet({
            url: `/wallet/usdr`
        }).then(res => {
            if (res.error)
                return
            setInvestWalletUsdr([res.data])
            setInvestWallet(res.data?.name)
        });

        // Запросить USDR кошелек для покупки
        apiGet({
            url: `/wallet/smart`
        }).then(res => {
            if (res.error)
                return

            if (res.data !== null) {
                setInvestWalletSmart([res.data])
            }
        });

        // Запросить цену подписки Prometey
        apiGet({
            url: '/prometey/price'
        }).then(res => {
            if (res.error)
                return;
            setPrometeyPrice(res.data)
        })

    }, []);


    const buyEducation = () => {
        setWaitingRequest(true);
        let data = {}
        data.qualifiedPartnerId = watch('qualificatedPartners')
        data.source = investWallet

        apiPost({
            url: `/prometey/buy-education`,
            postData: data
        }).then(res => {
            if (res.error) {
                setError(res.data.source);
                setWaitingRequest(false);
                return;
            }
            setTextAlert('Оплата прошла успешно')
            setSuccessModal(true)
            setWaitingRequest(false);
        })
    }

    const buySubscription = () => {
        let data = {}
        data.source = investWallet
        data.promoCode = watch('promoSubscribtionBuy')


        apiPost({
            url: `/prometey/buy`,
            postData: data
        }).then(res => {
            if (res.error) {
                return setError(res.data.source);
            }
            setTextAlert('Оплата прошла успешно')
            setSuccessModal(true)
        })
    }

    const handleChangeBillBlock = value => {
        setInvestWallet(value);
    };

    const renderBillBlock = ({name, balance, isCanPayProduct}) => {
        let balanced = +balance;
        return (
            <Col key={name} xs={12} sm={name === 'USDR' || name === 'SMART' ? 12 : 6}>
                <BillBlock
                    name={name}
                    value={name === 'USDT (ERC20)' || name === 'USDT (TRC20)' ? balanced.toFixed(2) : balanced}
                    activeClass={getActiveClass(name, investWallet)}
                    onClick={handleChangeBillBlock}
                />
            </Col>
        );
    };

    const closeModal = () => {
        setOpenBuyModal(false)
        setOpenBuySubscriptionModal(false)
        setError(null)
        setSuccessModal(false)
        setIsVideoVisible(false)

    }

    const openPrometeyModal = (value) => {
        if (value === 'subscribtion')
            setOpenBuySubscriptionModal(true)
        if (value === 'education')
            setOpenBuyModal(true)
        // setWhatIsBuy(value)
    }

    const handleAlertClose = () => {
        closeModal()
        setAlertView(false);
        setAlertText('');
    }

    const openVideo = url => {
        modalRef.current.style.height = `${
            modalRef.current.clientWidth * (9 / 16)
        }px`;
        setVideoUrl(url);
        setIsVideoVisible(true);
    };

    // const showMeMore = () => {
    //     setQualifiedPartnerName('s')
    //     console.log('ok')
    // }

    const prometeySecretChat = () => {
        apiGet({
            url: '/prometey/request-education-telegram-invite-link'
        }).then(res => {
            if (res.error)
                return;
            setSuccessModal(true);
            setTextAlert('Подключено');
        })
    }

    const handleAlertOpen = (text, textColor) => {
        setAlertView(true);
        setAlertText(text);
        setTextcolor(textColor);
    };

    return (
        <div className='p-8'>
            <div>
                <div className="flex items-center sm:mb-6 mb-3">
                    <div className="md:text-4xl sm:text-2xl text-xl  font-medium">
                        PROMETEY CLUB500
                    </div>
                    {/*{prometeySubscribe !== null ? (*/}
                    {/*    <Button*/}
                    {/*        color="blue"*/}
                    {/*        className="p-2 ml-3"*/}
                    {/*        onClick={() =>*/}
                    {/*            openVideo('https://www.youtube.com/embed/fs2Adi1YVWs')*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        <Icon name="playButton" color="#fff" size={16}/>*/}
                    {/*    </Button>*/}
                    {/*) : (*/}
                    {/*    <></>*/}
                    {/*)}*/}
                </div>
                {educationBuyTime ? <div className="font-medium">
                    Активировано: {educationBuyTime}
                </div> : <></>}
                {/*<button onClick={showMeMore}>show</button>*/}
                {qualificationPartners !== null && isBuyEducation !== true ?
                    <Select
                        fieldClass="w-full"
                        label="Список квалифицированных партнеров"
                        name='qualificatedPartners'
                        list={qualificationPartners}
                        register={register}
                    /> : <></>}

                <div className={qualifiedPartnerName !== null ? '' : ''}>
                    {isBuyEducation !== true &&
                        <Button className='h-10 mr-2 mb-2 max-xs:h-16 max-xs:w-48' color='primary'
                                onClick={() => openPrometeyModal('education')}>Купить обучение</Button>}
                    {qualifiedPartnerName !== null &&
                        <div className='mb-2'><strong>Ваш квалифицированный
                            партнер: {qualifiedPartnerName}</strong></div>
                    }
                    {qualifiedPartnerId ?
                        (telegramStatus ? <Button onClick={prometeySecretChat} className='h-10 max-xs:h-16 max-xs:w-48' color='primary'>Секретный чат Prometey</Button> : <div>Для подключения к приватному чату необходимо подключить телеграм-бота</div>)
                        : ''}
                </div>
                <br /><br />
                {educationKey ?
                    (
                        <div>
                            <h3 className="text-2xl">Ваш лицензионный ключ:</h3>
                            <div className="flex my-4 flex-wrap items-center sm:text-xs">
                                <span className="text my-1">{educationKey}</span>
                                <CopyToClipboard
                                    onCopy={() =>
                                        handleAlertOpen('Код успешно скопирован')
                                    }
                                    text={educationKey}
                                >
                                    <button className="ml-3 flex-nowrap flex items-center">
                                    <span className="icon flex items-center justify-center">
                                      <Icon name="copy" size={22} color={iconColor} />
                                    </span>
                                    </button>
                                </CopyToClipboard>
                                <span className="text ml-2 my-1">
                                    Действует 7 дней после активации
                                </span>
                            </div>
                        </div>
                    )
                    : ''}
            </div>

            <Modal
                title='Купить обучение Prometey'
                onClose={closeModal}
                isOpen={openBuyModal}
                headerStyles={{border: 'none'}}
                footerStyles={{border: 'none', padding: '10px'}}
                settings='alert'
                bodyStyles={{padding: '20px'}}
                modalStyles={{margin: 'auto'}}
                footer={waitingRequest ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> : <Button onClick={buyEducation} color="primary">Купить</Button>}
            >

                <div className='mb-6 text-2xl'>Стоимость обучения Prometey - 500 USDR/USDT</div>
                {error !== null && <div className='error'>{error}</div>}

                {/*<form onSubmit={handleSubmit(investToSmart)}>*/}
                <CardBody>

                    {/*<Input*/}
                    {/*    label={'Количество Smart INDEX'}*/}
                    {/*    // value={cryptoAmmount}*/}
                    {/*    register={register}*/}
                    {/*    name='investAmountCrypto'*/}
                    {/*    // onChange={handleInvestAmountChange}*/}
                    {/*/>*/}


                    {investWalletUsdr !== null && investWalletUsdr?.map(renderBillBlock)}
                    {investWalletSmart !== null && investWalletSmart?.map(renderBillBlock)}
                    <div className='flex flex-wrap'>
                        {walletDataCrypto !== null && walletDataCrypto?.map(renderBillBlock)}
                    </div>


                    {/*{walletDataCrypto === null ? <>*/}
                    {/*        <Spinner styles={{ margin: 'auto'}} size={50}/>*/}
                    {/*    </> :*/}
                    {/*    investWallet === 'ETH' || investWallet === 'BTC' ?*/}
                    {/*        `Общая сумма в ${investWallet}: ` + cryptoAmmount : ''*/}
                    {/*}*/}

                </CardBody>

                {/*<CardFooter>*/}
                {/*</CardFooter>*/}
                {/*</form>*/}
            </Modal>


            {/*Start -------Купить подписку*/}
            <Modal
                title='Купить подписку Prometey'
                onClose={closeModal}
                isOpen={openBuySubscriptionModal}
                // headerStyles={{border: 'none'}}
                // footerStyles={{border: 'none', padding: '10px'}}
                // settings='alert'
                // bodyStyles={{padding: '20px'}}
                // modalStyles={{margin: 'auto'}}
                footer={<Button onClick={buySubscription} color="primary">Купить</Button>}
            >
                {/*<form onSubmit={handleSubmit(investToSmart)}>*/}
                {prometeyPrice !== null &&
                    <div className='mb-6 text-2xl'>Стоимость подписки прометей - {prometeyPrice} Usdt/Usdr</div>}
                <div>
                    <Input
                        label={'Введите промо код'}
                        // value={cryptoAmmount}
                        register={register}
                        name='promoSubscribtionBuy'
                        // onChange={handleInvestAmountChange}
                    />
                    {error !== null && <div className='error'>{error}</div>}
                </div>

                {/*{investWalletUsdr !== null ?*/}
                {/*    investWalletUsdr?.map(renderBillBlock) :*/}
                {/*    <Spinner styles={{margin: 'auto'}} size={50}/>}*/}

                {investWalletUsdr !== null && investWalletUsdr?.map(renderBillBlock)}

                {/*{walletDataCrypto !== null ?*/}
                {/*    <div className='flex flex-wrap'>*/}
                {/*        {walletDataCrypto?.map(renderBillBlock)}*/}
                {/*    </div> : <Spinner styles={{margin: 'auto'}} size={50}/>}*/}
                <div className='flex flex-wrap'>
                    {walletDataCrypto !== null && walletDataCrypto?.map(renderBillBlock)}
                </div>
            </Modal>


            {/*End -------Купить подписку*/}


            {/*Start -- Уведомление*/}
            <Modal
                // title='Операция прошла успешно'
                title={textAlert}
                onClose={closeModal}
                isOpen={successModal}
                headerStyles={{border: 'none'}}
                footerStyles={{border: 'none', padding: '10px'}}
                settings='alert'
                bodyStyles={{padding: '0px'}}
                modalStyles={{margin: 'auto'}}
                footer={
                    <div className='flex justify-center'>
                        <Button onClick={handleAlertClose} color='primary'>Ок</Button>
                    </div>
                }
            />
            {/*End -------Уведомление*/}

            <Modal
                isOpen={isVideoVisible}
                modalStyles={{
                    maxWidth: 1280,
                    width: screenWidth < 600 ? '95%' : '60%',
                    margin: 'auto',
                }}
                onClose={closeModal}
                bodyStyles={{padding: 6, height: '100%'}}
            >
                <div ref={modalRef}>
                    {videoUrl && (
                        <iframe
                            className="modal-video"
                            src="https://www.youtube.com/embed/fs2Adi1YVWs"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    )}
                </div>
            </Modal>

            <Modal
                title={alertText}
                onClose={handleAlertClose}
                isOpen={alertView}
                headerStyles={{ border: 'none', color: `${textColor}` }}
                footerStyles={{ border: 'none', padding: '10px' }}
                settings="alert"
                bodyStyles={{ padding: '0px' }}
                modalStyles={{ margin: 'auto' }}
                footer={
                    <div className="flex justify-center">
                        <Col xs={'auto'}>
                            <Button onClick={handleAlertClose} color="primary">
                                Ок
                            </Button>
                        </Col>
                    </div>
                }
            />


        </div>
    );
};

export default PrometeyTeach;
