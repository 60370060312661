/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { PageHeader } from '../../../common/pageHeader';
import { apiGet, apiPost } from '../../../../api';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { useFetch } from '../../../../hooks/useFetch';
import { BillBlock } from '../../../common/billBlock';
import Spinner from '../../../common/spinner';
import { getActiveClass } from '../../../../helpers/className';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '../../../common/icon';
import { PrometeyVideo } from './PrometeyVideo';
import PrometeyLearning from './prometeyLearning';
import {Select} from "../../../common/ui/select";
import {useForm} from "react-hook-form";
import {Input} from "../../../common/ui/input";

// import { StaticBlock } from '../../../common/staticBlock';
// import { Badge } from '../../../common/badge';
// import { Checkbox } from '../../../common/checkbox';
// import { Input } from '../../../common/ui/input';
// import { useForm } from 'react-hook-form';
const PrometeiPage = () => {

  const { register, watch } = useForm();
  const screenWidth = window.screen.width;
  const { response: Prometey, fetchData: refreshPromteyData } = useFetch({
    url: '/prometey',
  });
  const { response: PrometeyPro, fetchData: refreshPromteyProData } = useFetch({
    url: '/prometey?type=2',
  });
  const [errObj, setErrObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [proModalVisible, setProModalVisible] = useState(false);
  const [threadsModalVisible, setThreadsModalVisible] = useState(false);
  const [investWallet, setInvestWallet] = useState('USDR');
  const [Summ, setYearSumm] = useState();
  const [keyPrice, setKeyPrice] = useState();
  const [keyPricePro, setKeyPricePro] = useState();
  const [threadPrice, setThreadPrice] = useState(null);
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [textColor, setTextcolor] = useState('#000');
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [waitingRequest, setWaitingRequest] = useState(false);
  const [threadsCount, setThreadsCount] = useState(1);

  // Telegram bot - Prometey
  const [isFetch, setIsFetch] = useState(false);
  const [telegramStatus, setTelegramStatus] = useState(false);
  const [inviteToken, setInviteToken] = useState('');
  const [walletDataUsdr, setWalletDataUsdr] = useState(null);
  const [walletDataSmart, setWalletDataSmart] = useState(null);

  // Крипто кошельки
  const [walletCryptoData, setWalletCryptoData] = useState(null);

  const [isUpdatePage, setIsUpdatePage] = useState(false);

  const [qualificationPartners, setQualificationPartners] = useState(null);
  const [qualifiedPartnerName, setQualifiedPartnerName] = useState(null);


  // const [inputVisible, setInputVisible] = useState(false);
  // const [promo, setPromo] = useState('');
  const [errObjText,setErrObjText] = useState();
  const modalRef = useRef(null);
  const iconColor = '#7c61bd';
  const { response: courseCrypto } = useFetch({ url: '/commissions' });
  // const { response: walletData, fetchData: refreshWalletData } = useFetch({
  //   url: '/wallet/crypto?onlyForPayProduct=1',
  //   defaultValue: {},
  // });

  const getWalletData = () => {
    apiGet({
      url: '/wallet/crypto?onlyForPayProduct=1',
    }).then(res => {
      if(res.error)
        return;
      setWalletCryptoData(res.data);
    });
  };

  useEffect(() => {
    getWalletData();
  },[]);

  const handleAlertOpen = (text, textColor) => {
    setAlertView(true);
    setAlertText(text);
    setTextcolor(textColor);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  useEffect(() => {
    // Список квалифицированных партнеров
    apiGet({
      url: '/prometey/qualified-partners-list'
    }).then(res => {
      if (res.error)
        return;

      let arr = []
      res.data.map(item => {
        let obj = {}
        obj.label = item.name
        obj.value = item.id
        arr.push(obj)
      })

      setQualificationPartners(arr);
    });
  },[]);

  useEffect(() => {
    apiGet({
      url: '/wallet/usdr'
    }).then(res => {
      if(res.error)
        return;
      setWalletDataUsdr([res.data]);
    });
  },[]);

  useEffect(() => {
    apiGet({
      url: '/wallet/smart'
    }).then(res => {
      if (res.error)
        return;

      if (res.data !== null) {
        setWalletDataSmart([res.data]);
      }
    });
  },[]);

  const renderBillBlock = ({ name, balance, isCanPayProduct }) => {
    let balanced = +balance;
    if (isCanPayProduct === false)
      return;

    return (
      <Col key={name} xs={12} sm={name === 'USDR' || name === 'SMART' ? 12 : 6}>
        <BillBlock
          name={name}
          value={name === 'USDR' ? balanced.toFixed(6) : balanced}
          activeClass={getActiveClass(name, investWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };

  const handleChangeBillBlock = value => {
    setInvestWallet(value);
    // let sum = Prometey?.data == null ? 1000 : 50;
    let comission =
        value === 'USDR' ? 1 : courseCrypto.data[`${value}`].REPLENISH_RATE.value;
    let num = keyPrice / comission;
    setYearSumm(num);
  };

  const OpenPrometeyModal = () => {
    // let sum = Prometey?.data == null ? 1000 : 50;

    setModalVisible(true);

    // setYearSumm(sum);
  };
  const OpenPrometeyProModal = () => {
    // let sum = PrometeyPro?.data == null ? 1000 : 50;

    setProModalVisible(true);

    // setYearSumm(sum);
  };
  const OpenPrometeyThreadsModal = () => {
    // let sum = Prometey?.data == null ? 1000 : 50;

    setThreadsModalVisible(true);

    // setYearSumm(sum);
  };
  const handleModalClose = async () => {
    // await refreshWalletData();
    getWalletData();
    setModalVisible(false);
    setProModalVisible(false);
    setThreadsModalVisible(false);
  };

  const BuyPrometey = async (type) => {
    setWaitingRequest(true);
    // await refreshWalletData();
    getWalletData();

    let qualifiedPartnerIdValue = null;
    if (type == 1) {
      qualifiedPartnerIdValue = watch('qualificatedPartners2');
    } else {
      qualifiedPartnerIdValue = watch('qualificatedPartners2Pro');
    }

    apiPost({
      url: '/prometey/buy?type=' + type,
      postData: {
        source: investWallet,
        qualifiedPartnerId: qualifiedPartnerIdValue,
        // promoCode: promo,
      },
    }).then(async res => {
      if (res.error) {
        setErrObj(res.data);
        setWaitingRequest(false);
        return console.log(res);
      }
      // if (res) {
      if (res.error && res.data.promoCode) {
        setErrObjText(res.data.promoCode[0]);
        setWaitingRequest(false);
        return;
      } else if (res.error) {
        setWaitingRequest(false);
        return;
      }

      setErrObj({});
      refreshPromteyData();
      // await refreshWalletData();
      getWalletData();
      handleModalClose();
      handleAlertOpen('Платеж отправлен', '#000');
      setWaitingRequest(false);
    }).catch(e => {
      console.log(e);
    });
  };

  const BuyPrometeyThreads = () => {
    setWaitingRequest(true);
    // await refreshWalletData();
    getWalletData();

    apiPost({
      url: '/prometey/buy-threads',
      postData: {
        source: investWallet,
        count: threadsCount,
      },
    }).then(async res => {
      if (res.error) {
        setErrObj(res.data);
        setWaitingRequest(false);
        return console.log(res);
      }
      // if (res) {
      if (res.error && res.data.promoCode) {
        setErrObjText(res.data.promoCode[0]);
        setWaitingRequest(false);
        return;
      } else if (res.error) {
        setWaitingRequest(false);
        return;
      }

      setErrObj({});
      refreshPromteyData();
      // await refreshWalletData();
      getWalletData();
      handleModalClose();
      handleAlertOpen('Платеж отправлен', '#000');
      setWaitingRequest(false);
    }).catch(e => {
      console.log(e);
    });
  }

  // const Contuneprometey = () => {
  //   apiPost({
  //     url: '/prometey/subscription-payment',
  //     postData: {
  //       source: investWallet,
  //     },
  //   }).then(res => {
  //     if (res) {
  //       if (res.error) {
  //         setErrObj(res.data);
  //         return;
  //       }
  //       if (!res.error) {
  //         setErrObj({});
  //         refreshWalletData();
  //         handleModalClose();
  //         refreshPromteyData();
  //         handleAlertOpen('Платеж отправлен', '#000');
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    refreshPromteyData();

    apiGet({
      url: '/prometey/price'
    }).then(res => {
      if(res.error)
        return console.log(res);
      setKeyPrice(res.data);
    }).catch(e => {
      console.log(e);
    });

    apiGet({
      url: '/prometey/price?type=2'
    }).then(res => {
      if(res.error)
        return console.log(res);
      setKeyPricePro(res.data);
    }).catch(e => {
      console.log(e);
    });

    apiGet({
      url: '/prometey/thread-price'
    }).then(res => {
      if(res.error)
        return console.log(res);
      setThreadPrice(res.data);
    }).catch(e => {
      console.log(e);
    });


    // apiGet({
    //   url: '/telegram',
    // }).then(res => {
    //   console.log(res);
    //   if (res.data === false) setTelegramStatus(false);
    //   else setTelegramStatus(true);
    //   setIsFetch(true);
    // });

  }, [isUpdatePage]);


  const checkTelegramBot = () => {
    // if (telegramStatus) {
    //   apiPost({
    //     url: '/telegram/disable',
    //   }).catch(e => {
    //     console.log(e);
    //   });
    // } else {
    apiGet({
      url: '/telegram/invite-link',
    })
      .then(res => {
        window.location.assign(res.data);
        // window.open(res.data, '');
      })
      .catch(e => {
        console.log(e);
      });
    // }

    apiGet({
      url: '/telegram',
    }).then(res => {
      if (res.data === false) {
        apiGet({
          url: '/telegram/invite-token',
        }).then(res => {
          if(res.error)
            return console.log(res);
          setInviteToken(res.data);
        }).catch(e =>{
          console.log(e);
        });
        setTelegramStatus(false);
      }
      else return setTelegramStatus(true);
    });
  };

  const copySecretTelegram = () => {
    apiGet({
      url: '/prometey/request-telegram-invite-link',
    })
      .then((res) => {

        if (res.data === 'Вы не подключены к Telegram Bot')
          console.log('Сначала нужно подключить телеграм бота');
          // handleAlertOpen('Сначала нужно подключить телеграм бота');
        else
          handleAlertOpen('ссылка на канал отправлена в телеграм бот');
      })
      .catch(e => {
        console.log(e);
      });
  };


  useEffect( () => {
    apiGet({
      url: '/telegram',
    }).then( res => {
      if (res.data === false) {
        setIsFetch(true);
        setTelegramStatus(false);

        apiGet({
          url: '/telegram/invite-token',
        }).then(res => {
          setInviteToken(res.data);
        }).catch(e =>{
          console.log(e);
        });
      }else {
        setTelegramStatus(true);
      }
    });
  }, []);

  const onChangeThreadsCount = (e) => {
    setThreadsCount(e.target.value);
  }


  return (
    <Container fluid>
      <Row>
        <PageHeader title="" />
        <div className="col-12">

          <Card>
            <PrometeyLearning setIsUpdatePage={setIsUpdatePage} isUpdatePage={isUpdatePage} telegramStatus={telegramStatus} qualificationPartners={qualificationPartners} />
          </Card>

          <Card>
            <CardBody className="card-body">
              <Row className="xl:flex-none xl:flex-column flex  items-center">
                <Col lg={12}>
                  {/*<div className="flex items-center sm:mb-6 mb-3">*/}
                    {/*<div className="md:text-4xl sm:text-2xl text-xl  font-medium">*/}
                    {/*    PROMETEY*/}
                    {/*</div>*/}
                    {/*{Prometey?.data !== null ? (*/}
                    {/*  <Button*/}
                    {/*    color="blue"*/}
                    {/*    className="p-2 ml-3"*/}
                    {/*    onClick={() =>*/}
                    {/*      openVideo('https://www.youtube.com/embed/fs2Adi1YVWs')*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    <Icon name="playButton" color="#fff" size={16} />*/}
                    {/*  </Button>*/}
                    {/*) : (*/}
                    {/*  <></>*/}
                    {/*)}*/}
                  {/*</div>*/}


                  <div className={'md:text-4xl sm:text-2xl text-xl  font-medium'}>
                      PROMETEY 3.0
                  </div>
                  <div className={'text-xl'}>
                    {keyPrice} USDR в месяц
                  </div>



                  {/*<Col sm={6} className="md:mb-8 mb-4 p-0">*/}
                  {/*  <StaticBlock*/}
                  {/*      bg="#48337b"*/}
                  {/*      value={keyPrice + ' USDR'}*/}
                  {/*      iconName="statHouse"*/}
                  {/*      title="Стоимость подписки Prometey"*/}
                  {/*  />*/}
                  {/*</Col>*/}
                  <br/>

                  {/*{Prometey?.data !== null ? (*/}
                  {/*  <>*/}
                  {/*    <></>*/}
                  {/*    <br />*/}
                  {/*  </>*/}
                  {/*) : (*/}
                  {/*  <PrometeyVideo />*/}
                  {/*)}*/}


                  {Prometey?.data !== null ? (
                    <>
                      <h3 className="text-2xl">Ваш лицензионный ключ:</h3>
                      <div className="flex my-4 flex-wrap items-center sm:text-xs">
                        <span className="text my-1">{Prometey?.data?.key}</span>
                        <CopyToClipboard
                          onCopy={() =>
                            handleAlertOpen('Код успешно скопирован')
                          }
                          text={Prometey?.data?.key}
                        >
                          <button className="ml-3 flex-nowrap flex items-center">
                            <span className="icon flex items-center justify-center">
                              <Icon name="copy" size={22} color={iconColor} />
                            </span>
                          </button>
                        </CopyToClipboard>
                        {/*<button onClick={() => console.log(Prometey)}>show</button>*/}
                        {Prometey?.data?.status
                          ?
                          <span className="text ml-2 my-1">
                              (Действует до: {Prometey?.data?.activeTo})
                          </span>
                          :
                          <span className="text-red ml-2 my-1">
                           (Подписка закончилась: {Prometey?.data?.activeTo})
                          </span>
                        }
                      </div>
                      <div className="flex my-4 flex-wrap items-center">
                        <span className="text my-1">
                             Количество потоков: {Prometey?.data?.threads_count}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {Prometey?.data === null ? <div style={{marginTop: '15px'}}>
                    {qualificationPartners !== null ? <Select
                        // fieldClass="w-full"
                        label="Список квалифицированных партнеров"
                        name='qualificatedPartners2'
                        list={qualificationPartners}
                        register={register}
                    /> : ''}
                  </div> : ''}

                  <Row className="text-center">
                    {Prometey?.data === null ? (
                      <>
                        <Col xs="auto">
                          <Button
                            className="my-3"
                            onClick={OpenPrometeyModal}
                            color="primary"
                            size="l"
                          >
                                Купить
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs="auto">
                          <Button
                            className="my-3 max-xs:h-16 max-xs:w-48"
                            onClick={OpenPrometeyModal}
                            color="primary"
                            size="l"
                          >
                                Продлить
                          </Button>
                        </Col>
                        <Col xs="auto">
                          <Button
                              className="my-3 max-xs:h-16 max-xs:w-48"
                              onClick={OpenPrometeyThreadsModal}
                              color="primary"
                              size="l"
                          >
                            Купить потоки
                          </Button>
                        </Col>
                        {/*<Col xs="auto">*/}
                        {/*  <a*/}
                        {/*    href="https://api.rsi-capital.com/prometey/Prometey2.exe"*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noopener noreferrer"*/}
                        {/*  >*/}
                        {/*    <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">*/}
                        {/*          Скачать приложение*/}
                        {/*    </Button>*/}
                        {/*  </a>*/}
                        {/*</Col>*/}
                      </>
                    )}
                    <Col xs="auto">
                      <a
                        href="http://t.me/prometeycapital"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">
                            Наш телеграм
                        </Button>
                      </a>
                    </Col>
                    {/*<Col xs="auto">*/}
                    {/*  <a*/}
                    {/*    href="https://api.rsicrypto.com/docs/prometey.pdf"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*  >*/}
                    {/*    <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">*/}
                    {/*        PDF Презентация*/}
                    {/*    </Button>*/}
                    {/*  </a>*/}
                    {/*</Col>*/}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="card-body">
              <Row className="xl:flex-none xl:flex-column flex  items-center">
                <Col lg={12}>
                  {/*<div className="flex items-center sm:mb-6 mb-3">*/}
                  {/*<div className="md:text-4xl sm:text-2xl text-xl  font-medium">*/}
                  {/*    PROMETEY*/}
                  {/*</div>*/}
                  {/*{Prometey?.data !== null ? (*/}
                  {/*  <Button*/}
                  {/*    color="blue"*/}
                  {/*    className="p-2 ml-3"*/}
                  {/*    onClick={() =>*/}
                  {/*      openVideo('https://www.youtube.com/embed/fs2Adi1YVWs')*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <Icon name="playButton" color="#fff" size={16} />*/}
                  {/*  </Button>*/}
                  {/*) : (*/}
                  {/*  <></>*/}
                  {/*)}*/}
                  {/*</div>*/}


                  <div className={'md:text-4xl sm:text-2xl text-xl  font-medium'}>
                    PROMETEY PRO
                  </div>
                  <div className={'text-xl'}>
                    {keyPricePro} USDR в месяц
                  </div>

                  <br/>

                  {PrometeyPro?.data !== null ? (
                      <>
                        <h3 className="text-2xl">Ваш лицензионный ключ:</h3>
                        <div className="flex my-4 flex-wrap items-center sm:text-xs">
                          <span className="text my-1">{PrometeyPro?.data?.key}</span>
                          <CopyToClipboard
                              onCopy={() =>
                                  handleAlertOpen('Код успешно скопирован')
                              }
                              text={PrometeyPro?.data?.key}
                          >
                            <button className="ml-3 flex-nowrap flex items-center">
                            <span className="icon flex items-center justify-center">
                              <Icon name="copy" size={22} color={iconColor} />
                            </span>
                            </button>
                          </CopyToClipboard>
                          {/*<button onClick={() => console.log(PrometeyPro)}>show</button>*/}
                          {PrometeyPro?.data?.status
                              ?
                              <span className="text ml-2 my-1">
                              (Действует до: {PrometeyPro?.data?.activeTo})
                          </span>
                              :
                              <span className="text-red ml-2 my-1">
                           (Подписка закончилась: {PrometeyPro?.data?.activeTo})
                          </span>
                          }
                        </div>
                        <div className="flex my-4 flex-wrap items-center">
                        <span className="text my-1">
                             Количество потоков: {PrometeyPro?.data?.threads_count}
                        </span>
                        </div>
                      </>
                  ) : (
                      <></>
                  )}

                  {PrometeyPro?.data === null ? <div style={{marginTop: '15px'}}>
                    {qualificationPartners !== null ? <Select
                        // fieldClass="w-full"
                        label="Список квалифицированных партнеров"
                        name='qualificatedPartners2Pro'
                        list={qualificationPartners}
                        register={register}
                    /> : ''}
                  </div> : ''}

                  <Row className="text-center">
                    {PrometeyPro?.data === null ? (
                        <>
                          <Col xs="auto">
                            <Button
                                className="my-3"
                                onClick={OpenPrometeyProModal}
                                color="primary"
                                size="l"
                            >
                              Купить
                            </Button>
                          </Col>
                        </>
                    ) : (
                        <>
                          <Col xs="auto">
                            <Button
                                className="my-3 max-xs:h-16 max-xs:w-48"
                                onClick={OpenPrometeyProModal}
                                color="primary"
                                size="l"
                            >
                              Продлить
                            </Button>
                          </Col>
                          {/*<Col xs="auto">*/}
                          {/*  <Button*/}
                          {/*      className="my-3 max-xs:h-16 max-xs:w-48"*/}
                          {/*      onClick={OpenPrometeyThreadsModal}*/}
                          {/*      color="primary"*/}
                          {/*      size="l"*/}
                          {/*  >*/}
                          {/*    Купить потоки*/}
                          {/*  </Button>*/}
                          {/*</Col>*/}
                          {/*<Col xs="auto">*/}
                          {/*  <a*/}
                          {/*    href="https://api.rsi-capital.com/prometey/Prometey2.exe"*/}
                          {/*    target="_blank"*/}
                          {/*    rel="noopener noreferrer"*/}
                          {/*  >*/}
                          {/*    <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">*/}
                          {/*          Скачать приложение*/}
                          {/*    </Button>*/}
                          {/*  </a>*/}
                          {/*</Col>*/}
                        </>
                    )}
                    <Col xs="auto">
                      <a
                          href="http://t.me/prometeycapital"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">
                          Наш телеграм
                        </Button>
                      </a>
                    </Col>
                    {/*<Col xs="auto">*/}
                    {/*  <a*/}
                    {/*    href="https://api.rsicrypto.com/docs/prometey.pdf"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*  >*/}
                    {/*    <Button className="my-3 max-xs:h-16 max-xs:w-48" color="primary" size="l">*/}
                    {/*        PDF Презентация*/}
                    {/*    </Button>*/}
                    {/*  </a>*/}
                    {/*</Col>*/}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="card-body">

              <div className='sm:text-s text-sm font-small my-5'>
                {
                  telegramStatus ? <></> :
                    <>
                        Для получения доступа к закрытому каналу - подключите телеграм бот (если возникли сложности с
                        подключением попробуйте с мобильного устройства)
                    </>
                }

                <div>
                  {telegramStatus ?
                  // {true ?
                    <>
                      {/*{*/}
                      {/*  Prometey?.data?.status === true ? (*/}
                      {/*    <div>*/}
                      {/*      <Button*/}
                      {/*        onClick={copySecretTelegram}*/}
                      {/*        className='my-2 mx-0'*/}
                      {/*        color={'primary'}*/}
                      {/*        size='m'*/}
                      {/*      >Секретный телеграм канал</Button>*/}
                      {/*    </div>) :*/}
                      {/*    <div className={'font-bold font-medium'}>*/}
                      {/*            *Оформите подписку Prometey для получения ссылки на секретный канал*/}
                      {/*    </div>*/}
                      {/*}*/}
                      <div className={'font-bold font-medium'}>
                        Telegram Bot подключен
                      </div>
                    </>
                    :
                    <>
                      { isFetch ?
                        <div>
                          <div>
                            <Button
                              onClick={checkTelegramBot}
                              className='my-5 mx-0'
                              color={'primary'}
                              size='m'
                            >Подключиться к телеграм боту
                            </Button>
                          </div>
                          <div className='sm:text-s text-sm font-small my-5'>
                            <strong>
                                        Если Вам не удается совершить подключение к боту, воспользуйтесь следующей инструкцией:
                            </strong>
                            <ul>
                              <li className={'my-2'}>
                                          1. Найдите бота по имени пользователя в приложении Telegram - @RSIfundBot
                              </li>
                              <li className={'my-2'}>
                                          2. Нажмите на кнопку "старт", "начать", "start", "запустить" (кнопка в разных версиях называется по-разному) или просто напишите боту "/start" (без кавычек), если кнопки нет.
                              </li>
                              <li className={'my-2'}>
                                          3. Бот должен попросить Вас отправить ему токен.
                              </li>
                              <li className={'mb-3'}>
                                <strong className={'mr-3'}>Вот Ваш токен:</strong>
                                <span>
                                  {inviteToken}
                                </span>
                                <CopyToClipboard
                                  onCopy={() => handleAlertOpen('Токен успешно скопирован')}
                                  text={inviteToken}>
                                  <span>
                                    <button className={'mx-2'}>
                                      <Icon name="copy" size={22} color={'#7c61bd'} />
                                    </button>
                                  </span>
                                </CopyToClipboard>
                              </li>

                              <li className={'my-2'}>
                                          4. Если всё в порядке, бот ответит, что Вы успешно авторизовались.
                              </li>
                            </ul>
                          </div>
                        </div> : <></>
                      }</>
                  }
                </div>
              </div>
            </CardBody>
          </Card>





          {/*<Col xs="auto">*/}

          {/*{isFetch && !telegramStatus ? <Button*/}
          {/*    onClick={checkTelegramBot}*/}
          {/*    className="my-7 mx-0"*/}
          {/*    color={'primary'}*/}
          {/*    size="m"*/}
          {/*>*/}
          {/*  Подключиться к telegram bot*/}
          {/*</Button> : <></>*/}
          {/*}*/}

          {/*{isFetch && telegramStatus ?*/}
          {/*    <div className="row">*/}
          {/*      <Col className="mb-5 lg:text-sm text-lg font-medium">*/}
          {/*        Вы подключены к телеграм боту*/}
          {/*      </Col>*/}
          {/*    </div> : <></>*/}
          {/*}*/}

          {/*</Col>*/}


          {/* <Col xs="auto">
                      <Button className="my-3" color="primary" size="l">
                        Обучение
                      </Button>
                    </Col> */}

        </div>
      </Row>
      <Modal
        title={`Стоимость: ${keyPrice} USDR`}
        onClose={handleModalClose}
        isOpen={modalVisible}
      >
        {walletDataUsdr !== null ? walletDataUsdr?.map(renderBillBlock) :
          <Spinner styles={{ margin: 'auto'}} size={50} />}

        {/*{walletDataSmart !== null ? walletDataSmart?.map(renderBillBlock) : ''}*/}

        {walletCryptoData !== null ?
          <div className='flex flex-wrap'>
            {walletCryptoData?.map(renderBillBlock)}
          </div> : <Spinner styles={{ margin: 'auto'}} size={50} />}
        <br />
        <div className="text-xl">
          {
            investWallet === 'USDR' ? 'Сумма: ' + keyPrice + ' USDR' :
              <>Сумма: {keyPrice} {investWallet}</>
          }
        </div>
        <br />
        <div className="flex flex-col items-center justify-center">
          {waitingRequest
            ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/>
            : <Button
                color="primary"
                size="xl"
                // onClick={Prometey?.data == null ? BuyPrometey : Contuneprometey}
                onClick={() => BuyPrometey(1)}
              >
                Подтвердить
              </Button>}
          {errObj?.source && (
            <div className=" mt-2 error">{errObj?.source}</div>
          )}
          {errObjText && <div className="error">{errObjText}</div>}
        </div>
      </Modal>

      <Modal
          title={`Стоимость: ${keyPricePro} USDR`}
          onClose={handleModalClose}
          isOpen={proModalVisible}
      >
        {walletDataUsdr !== null ? walletDataUsdr?.map(renderBillBlock) :
            <Spinner styles={{ margin: 'auto'}} size={50} />}

        {/*{walletDataSmart !== null ? walletDataSmart?.map(renderBillBlock) : ''}*/}

        {walletCryptoData !== null ?
            <div className='flex flex-wrap'>
              {walletCryptoData?.map(renderBillBlock)}
            </div> : <Spinner styles={{ margin: 'auto'}} size={50} />}
        <br />
        <div className="text-xl">
          {
            investWallet === 'USDR' ? 'Сумма: ' + keyPricePro + ' USDR' :
                <>Сумма: {keyPricePro} {investWallet}</>
          }
        </div>
        <br />
        <div className="flex flex-col items-center justify-center">
          {waitingRequest
              ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/>
              : <Button
                  color="primary"
                  size="xl"
                  // onClick={Prometey?.data == null ? BuyPrometey : Contuneprometey}
                  onClick={() => BuyPrometey(2)}
              >
                Подтвердить
              </Button>}
          {errObj?.source && (
              <div className=" mt-2 error">{errObj?.source}</div>
          )}
          {errObjText && <div className="error">{errObjText}</div>}
        </div>
      </Modal>

      <Modal
          title={`Стоимость: ${threadPrice * threadsCount} USDR`}
          onClose={handleModalClose}
          isOpen={threadsModalVisible}
      >
        <Input
            fieldClass={'w-full ml-4 mb-4 mb-0'}
            label="Количество потоков"
            type="number"
            onChange={(e) => onChangeThreadsCount(e)}
            name="threadsCount"
            value={threadsCount}
            placeholder='Количество потоков'
            register={register}
        />

        {walletDataUsdr !== null ? walletDataUsdr?.map(renderBillBlock) :
            <Spinner styles={{ margin: 'auto'}} size={50} />}

        {/*{walletDataSmart !== null ? walletDataSmart?.map(renderBillBlock) : ''}*/}

        {walletCryptoData !== null ?
            <div className='flex flex-wrap'>
              {walletCryptoData?.map(renderBillBlock)}
            </div> : <Spinner styles={{ margin: 'auto'}} size={50} />}
        <br />
        <div className="text-xl">
          {
            investWallet === 'USDR' ? 'Сумма: ' + (threadPrice * threadsCount) + ' USDR' :
                <>Сумма: {(threadPrice * threadsCount)} {investWallet}</>
          }
        </div>
        <br />
        <div className="flex flex-col items-center justify-center">
          {waitingRequest
              ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/>
              : <Button
                  color="primary"
                  size="xl"
                  // onClick={Prometey?.data == null ? BuyPrometey : Contuneprometey}
                  onClick={BuyPrometeyThreads}
              >
                Подтвердить
              </Button>}
          {errObj?.source && (
              <div className=" mt-2 error">{errObj?.source}</div>
          )}
          {errObjText && <div className="error">{errObjText}</div>}
        </div>
      </Modal>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none', color: `${textColor}` }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                    Ок
              </Button>
            </Col>
          </div>
        }
      />
      {Prometey?.data !== null ? (
        <Modal
          isOpen={isVideoVisible}
          modalStyles={{
            maxWidth: 1280,
            width: screenWidth < 600 ? '95%' : '60%',
            margin: 'auto',
          }}
          onClose={closeVideo}
          bodyStyles={{ padding: 6, height: '100%' }}
        >
          <div ref={modalRef}>
            {videoUrl && (
              <iframe
                className="modal-video"
                src="https://www.youtube.com/embed/fs2Adi1YVWs"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}

    </Container>
  );
};

export default PrometeiPage;
